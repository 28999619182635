<template>
    <div v-bind:id="chat._id">
        <div class="chat-card card shadow border-0 rounded-xl h-100">
            <div class="card-body">
                <h5>
                    <span class="badge badge-whatsapp mr-1"
                          v-if="type === 'whatsapp'">
                        <font-awesome-icon v-bind:icon="['fab', 'whatsapp']"></font-awesome-icon> WhatsApp
                    </span>
                    <span class="badge badge-info mr-1"
                          v-if="type === 'telegram'">
                        <font-awesome-icon v-bind:icon="['fab', 'telegram']"></font-awesome-icon> Telegram
                    </span>
                    <span class="badge badge-primary px-2 mr-1"
                          v-if="members.length >= 255">
                        Full
                    </span>
                </h5>
                <div class="d-flex flex-nowrap">
                    <h4 class="card-title">
                        {{ chat.name }}
                    </h4>
                </div>
                <p class="card-text"
                   v-line-clamp:14="3">
                    {{ chat.desc }}
                </p>
            </div>
            <b-progress class="position-relative rounded-0"
                        v-bind:max="255"
                        style="height: 18px; ">
                <div class="position-absolute group-member-count font-weight-bold text-center h-100 w-100">
                    {{ members.length }}/255 members ({{ Math.round(members.length / 255 * 100) }}%)
                </div>
                <b-progress-bar variant="info"
                                v-bind:value="members.length">
                </b-progress-bar>
                <!-- <b-progress-bar class="progress-male"
                                v-bind:value="50">
                    Male 50
                </b-progress-bar>
                <b-progress-bar class="progress-female"
                                v-bind:value="50">
                    Female 50
                </b-progress-bar> -->
            </b-progress>

            <chat-statistics class="border-bottom"
                             v-bind:chat="chat">
            </chat-statistics>

            <div class="d-flex justify-content-between p-2">
                <router-link class="btn btn-outline-dark border-0"
                             v-bind:to="`/chats/${ chat._id }/members`">
                    Members
                </router-link>
                <router-link class="btn btn-outline-dark border-0"
                             v-bind:to="`/chats/${ chat._id }`">
                    Chats
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { BProgress, BProgressBar, } from "bootstrap-vue";
export default {
    name: "ChatCard",
    components: {
        BProgress, BProgressBar,
        ChatStatistics: () => import("./ChatCard/ChatStatistics"),
    },
    props: {
        "type": {
            type: String,
            required: true,
        },
        "chat": {
            type: Object,
            required: true,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        members () {
            if (Array.isArray(this.chat.members)) {
                return this.chat.members;
            }
            return [];
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.badge-whatsapp {
    background-color: #00bfa5;
    color: rgba(255, 255, 255, 1);
}

.group-member-count {
    top: 50%;
    left: 0;
}

.chat-card {
    & > .card-body {
        .card-text {
            white-space: pre-line;
        }
    }

    .progress-male {
        background-color: #2766f6;
    }

    .progress-female {
        background-color: #fc363b;
    }
}

.chat-content {
    position: relative;

    .messages {
        &.whatsapp {
            background-image: url("../../assets/whatsapp_bg.png");
            background-repeat: repeat;
            background-size: cover;
        }

        height: 350px;
        overflow: auto;

        letter-spacing: normal;
        outline: none;
        padding: 0 12px;
    }
}
</style>
